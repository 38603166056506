import React from "react"
import { Link } from "gatsby"
import Logo from "../assets/name.svg"
import styled from "@emotion/styled"
import Banner from "../assets/pagan-symbol-banner-final-WHITE.svg"

const Nav = styled.nav`
  .activeNavItem {
    font-weight: bold;
  }
`
const Header = () => {
  return (
    <>
      <header className="julius relative flex justify-center overflow-hidden align-middle border-gray-200 h-66 px-3 sm:px-0 ">
        <section className=" w-screen flex flex-col max-w-6xl">
          <Banner className="absolute right-0 min-h-full patterns" />{" "}
          <Link to="/" className=" m-auto">
            <Logo className=" mt-12 mb-6" style={{ width: "145px" }} />{" "}
          </Link>
          <h2 className="julius text-base m-auto mb-16 align-middle text-center">
            Paintings and drawings{" "}
            <span className=" text-gray-800 beenie text-lg px-1"> by </span>
            <strong>Izabelle Wilding</strong>
          </h2>
          <p className="karla text-base m-auto mb-13 align-middle text-center max-w-md">
            Izabelle is a 25 year old Welsh artist currently based in
            Carmarthenshire, with time also spent living in Bristol and London.
            Her works utilise a range of different mediums, keeping her style
            varied and vibrant. Many of the pieces featured on this website were
            created solely from imagination, others are drawn from inspiring
            scenes and images of nature.
          </p>
          {/* <div className="w-full md:text-md whitespace-no-wrap flex flex-col justify-between md:flex-row mb:30 text-center">
            <Nav className="julius text-sm uppercase flex flex-col md:flex-row m-auto text-gray-700 mb-16 mt-3">
              <Link
                to="/"
                className="text-center mx-3"
                activeClassName="activeNavItem"
              >
                Home
              </Link>
              <Link
                to="/sketchbook"
                activeClassName="activeNavItem"
                className="text-center mx-3"
              >
                Sketchbook
              </Link>
              <Link
                to="/watercolours"
                className="text-center mx-3"
                activeClassName="text-semibold text-gray-900"
              >
                Watercolours
              </Link>
              <Link
                to="/contact"
                activeClassName="activeNavItem"
                className="text-cente mx-3  hover:text-gray-500"
              >
                Contact
              </Link>
            </Nav>
          </div> */}
        </section>
      </header>
    </>
  )
}

export default Header
