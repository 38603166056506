import React from "react"
import { Link } from "gatsby"
import Bird from "../assets/bird-green.svg"

const Footer = () => {
  return (
    <div>
      <footer className=" bg-gray-100 w-full m-auto flex flex-col md:flex-row border-t border-gray-200 p-8 overflow-hidden">
        <section className="relative max-w-6xl w-full flex flex-col md:flex-row m-auto">
          <div className="flex mb-4">
            <div className=" w-6/12 mr-12">
              <h3 className="font-semibold garamond margin-bottom whitespace-no-wrap">
                Have a question?
              </h3>
              <Link
                className="text-lightPrimary chivo-reg uppercase text-xs border-gray-900 border-2 hover:bg-gray-800 hover:text-white mb-6 py-2 px-3 whitespace-no-wrap"
                to="/contact"
              >
                Get in touch{" "}
              </Link>
            </div>
            {/* social icons */}
            {/* <div className="mt-6 flex flex-row">
            <a
              href="https://github.com/izabellewilding"
              target="_blank"
              rel="noopener noreferrer"
              className=" bg-white flex shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
              
            ></a>{" "}
            <a
              href="https://www.linkedin.com/in/izabelle-wilding-b68a73130/"
              target="_blank"
              rel="noopener noreferrer"
              className=" bg-white shadow-lg flex font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
              
            ></a>
            <a
              href="mailto:izabellewilding@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
              className=" bg-white text-gray-900 shadow-lg font-normal h-10 w-10 flex items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
              
            ></a>{" "}
          </div>{" "} */}
          </div>{" "}
          <div className="mr-12 flex-col flex text-left whitespace-no-wrap">
            <Bird
              style={{
                height: "32rem",
                top: "-14.5rem",
                opacity: "0.2",
                zIndex: "1",
              }}
              className="absolute right-0"
            />
          </div>
        </section>
      </footer>
    </div>
  )
}

export default Footer
